// Elements displayed in the topmost region of the app

h1 {
  margin-top: $chip-size + ($chip-margin * 2);
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 32px;
  @include if-hybrid-layout() {
    margin-top: $page-spacing-top;
  }
}
.nav-link {
  position: absolute;
  top: 8px;
  font-size: 14px;
  $position-x: 10px;
  &-left {
    left: $position-x;
  }
  &-right {
    right: $position-x;
  }
  a {
    color: $input-focus-border-color;
    text-decoration: none;
  }
}
